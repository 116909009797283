<script>
import PageHeader from '@/components/shared/pageHeader'
import pageActions from '@/components/shared/pageActions'
import ModifierGroupDialog from '@/components/dialogs/group/modifierGroupDialog'
import SupprimerGroupDialog from '@/components/dialogs/group/supprimerGroupDialog'

export default {
  name: 'ClasseDetail',
  components: {
    PageHeader,
    pageActions,
    ModifierGroupDialog,
    SupprimerGroupDialog
  },
  data () {
    return {
      group: {},
      loading: true,
      actionsLine: [
        [
          { title: 'Modifier le titre ou la<br/>description du groupe', click: () => { this.showModifierGroup = !this.showModifierGroup } },
          { title: 'Supprimer le groupe', click: () => { this.showSupprimerGroup = !this.showSupprimerGroup } },
          { title: 'Créer et associer des<br/>comptes élèves' }
        ],
        [
          { title: 'Recevoir les identifiants des élèves par mail' },
          { title: 'Voir/imprimer les codes QR de<br/>connexion rapide des élèves' }
        ]
      ],
      showModifierGroup: false,
      showSupprimerGroup: false
    }
  },
  mounted () {
    this.fetchSalleClasse()
  },
  computed: {
    chips () {
      return [
        {
          icon: ['fad', 'calendar-alt'],
          label: this.$moment(this.group?.finAt).locale(this.$i18n.locale).format('DD MMMM YYYY')
        },
        {
          icon: ['fad', 'users'],
          label: this.$tc('classe.nb-eleves', this.group?.usagers?.total, { count: this.group?.usagers?.total })
        },
        {
          icon: ['fad', 'chalkboard-teacher'],
          label: this.group?.usager ? `${this.group.usager.info.prenom} ${this.group.usager.info.nom}` : ''
        }
      ].map(c => ({ ...c, loading: this.loachalkboding }))
    }
  },
  methods: {
    async fetchSalleClasse () {
      this.loading = true

      const { data: { searchSalleClasses } } = await this.$apollo.query({
        query: require('@/graphql/queries/v2/searchSalleClassesById.gql'),
        variables: {
          filtre: {
            salles: [this.$route?.params?.id]
          }
        },
        fetchPolicy: 'no-cache'
      })

      this.group = searchSalleClasses?.resultat[0]

      this.loading = false
    },
    goBackToGroupsList () {
      this.$router.push({
        name: 'classes___' + this.$i18n.locale,
        params: { lang: this.$i18n.locale }
      })
    }
  }
}
</script>

<template lang="pug">
.classe-detail
  page-header(
    :title="loading ? 'Chargement...' : group.titre"
    :subtitle="loading ? null : group.description"
    :backButtonLabel="$t('menu.mes-classes')"
    :backButtonTo="{ name: 'classes___' + $i18n.locale, params: { lang: $i18n.locale }  }"
    :chips="chips"
  )
  div(v-for="(actionLine, index) in actionsLine" :key="`actionline-${index}`")
    page-actions(:actions="actionLine")

  modifier-group-dialog(v-if="group && showModifierGroup" :group="group" @toggle="showModifierGroup = !showModifierGroup" @refetch="fetchSalleClasse")
  supprimer-group-dialog(v-if="group && showSupprimerGroup" :group="group" @toggle="showSupprimerGroup = !showSupprimerGroup" @refetch="goBackToGroupsList")

</template>
