<script>
import ContextBearer from '@/mixins/contextBearer'

export default {
  name: 'ModifierGroupDialog',
  mixins: [ContextBearer],
  props: {
    group: { type: Object, required: true }
  },
  data () {
    return {
      isOpen: true,
      titre: '',
      description: '',
      loadingSauvegarde: false
    }
  },
  mounted () {
    this.titre = this.group.titre
    this.description = this.group.description
  },
  methods: {
    async save () {
      this.loadingSauvegarde = true

      const { username, accessCode } = this.group?.usager?.info
      const contextBearer = await this.generateBearerFromUsernameAndAccessCode(username, accessCode)

      const { data } = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/v2/updateSalleClasse.gql'),
        variables: {
          contextBearer,
          id: this.group.id,
          attrs: {
            titre: this.titre,
            description: this.description
          }
        }
      })

      if (!data?.updateSalleClasse?.id || data?.updateSalleClasse?.errors) {
        this.$store.dispatch('App/setSnackBarNotification', {
          type: 'error',
          description: 'Échec de la modification du groupe'
        })
      }

      this.loadingSauvegarde = false
      this.$emit('refetch')
      this.$emit('toggle')
    }
  }
}
</script>

<template lang="pug">
v-dialog.modifier-group-dialog(v-model="isOpen" max-width="600" content-class="custom-dialog" persistent)
  v-card.light
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click="$emit('toggle')" depressed fab x-large)
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title.secondary.white--text {{ $t('classe.modifier-groupe') }}
    v-card-text.dark--text
      div.dialog-text
        v-text-field.code-input(light :placeholder='"Nom du groupe"' solo hide-details v-model='titre' clearable rounded :disabled="loadingSauvegarde")
        v-textarea(v-model='description' outlined :placeholder='"Description"' :label='"Description du groupe"' background-color='white' color='secondary' :disabled="loadingSauvegarde")

    v-btn(@click='save' color='primary' depressed block x-large :disabled='!titre || loadingSauvegarde' :loading="loadingSauvegarde")
      span.f4 {{ $t('classe.modifier') }}

</template>
