<script>
import ContextBearer from '@/mixins/contextBearer'

export default {
  name: 'ModifierGroupDialog',
  mixins: [ContextBearer],
  props: {
    group: { type: Object, required: true }
  },
  data () {
    return {
      isOpen: true,
      confirmWord: '',
      loadingDeleting: false
    }
  },
  mounted () {
    this.titre = ''
  },
  methods: {
    async deleteGroup () {
      this.loadingDeleting = true

      const { username, accessCode } = this.group?.usager?.info
      const contextBearer = await this.generateBearerFromUsernameAndAccessCode(username, accessCode)

      const { data } = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/v2/updateSalleClasse.gql'),
        variables: {
          contextBearer,
          id: this.group.id,
          attrs: {
            statut: 'INACTIF'
          }
        }
      })

      if (!data?.updateSalleClasse?.id || data?.updateSalleClasse?.errors) {
        this.$store.dispatch('App/setSnackBarNotification', {
          type: 'error',
          description: this.$t('alerte.suppr-group-error')
        })
      }

      this.loadingDeleting = false
      this.$emit('refetch')
      this.$emit('toggle')
    }
  }
}
</script>

<template lang="pug">
v-dialog.supprimer-group-dialog(v-model='isOpen' max-width='600' content-class='custom-dialog' persistent)
  v-card.light
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click="$emit('toggle')" depressed fab x-large)
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title.secondary.white--text {{ $t('classe.suppr-group')}}
    v-card-text.relative
      div.dialog-text(v-if='group')
        div
          span {{ $t('classe.confirm-suppr') }}
          span.b  {{group.titre}},
          span  {{ $t('classe.enter-effacer') }}
    v-card-text.relative
      .dialog-text
        v-text-field.code-input(light color='secondary' :placeholder='$t("action.effacer-keyword")' solo hide-details v-model='confirmWord' clearable rounded)
    v-btn(@click='deleteGroup' color='primary' depressed block x-large :disabled='confirmWord !== $t("action.effacer-keyword") || loadingDeleting' :loading="loadingDeleting")
      span.f4 {{ $t('action.supprimer') }}
</template>
