<script>
export default {
  name: 'PageActions',
  props: {
    actions: { type: Array, required: true }
  },
  methods: {
    empty () {
      return ''
    }
  }
}
</script>

<template lang="pug">
.page-actions
  v-card.pointer(
    v-for="(action, index) in actions"
    :key="`action-${index}`"
    color="primary"
    v-ripple
    @click="action.click ? action.click() : empty()"
  )
    v-card-title(v-html="action.title")
</template>
